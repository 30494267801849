@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
body {
  background-color: #efefef;
  margin: 0;
  padding: 0;
  width: 100%;
}

ul {
  list-style: none;
  margin-left: -40px;
}

h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.myparcel {
  color: #0a5d43;
}

.usercard {
  margin-bottom: 20px;
  width: 400px;
  height: auto;
  padding: 10px;
  text-align: left;
  background-color: white;
  border-radius: 7px;
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.75);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-size: medium;
  font-weight: 500;
}

.formbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border: none;
  border-radius: 50%; /* Maak de knoppen rond */
  width: 20px; /* Bepaal de grootte van de knop */
  height: 20px; /* Zorg dat de knop een vierkant is */
  cursor: pointer;
}

.material-icons {
  font-size: 20px; /* Maak het pictogram iets groter */
}

.formbutton:disabled {
  width: 20px;
  height: 20px;
  cursor: default;
  color: crimson;
  background-color: #efefef;
}

.main {
  /* height: auto; */
  display: grid;
  grid-template-columns: repeat(2, 490px);
  padding: 75px 75px 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: medium;
}

.pr-data {
  align-items: center;
  width: 455px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  /* column-gap: 5px; */
  margin: 0px;
  /* border: 1px dashed red; */
}

hr {
  width: 455px;
  margin: 0px;
}

.login {
  font-size: medium;
  padding-bottom: 1em;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.order-form {
  align-items: center;
  width: 200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 5px;
}

label {
  max-width: 130px;
  font-size: medium;
  padding-right: 1em;
}

input {
  width: 300px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: medium;
  font-weight: 400;
}

input[type="text"]:disabled {
  background: #ffffff;
  font-size: medium;
  border: none;
}

input[type="text"]:focus {
  outline: none;
  background: rgb(226, 249, 255);
}

select {
  width: 304px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: medium;
}

.input-disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.input-active {
  background: rgb(226, 249, 255);
  color: #000; /* Zwarte tekst */
  cursor: text; /* Cursor verandert naar tekstselectie */
}

.input-active:focus {
  outline: none; /* Verwijder standaard focusstijl */
}

.loginContainer {
  padding: 75px 75px;
  display: block;
}

.login-button {
  width: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  color: #ffffff;
  background-color: #0875c2;
  border: none;
}

button:disabled {
  width: auto;
  background-color: rgb(185, 185, 185);
  color: white;
  border: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: medium;
  cursor: default;
}

.order {
  width: auto;
  background-color: #0a5d43;
  color: #ff8a00;
  border: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: medium;
  cursor: pointer;
}

.required_field {
  color: crimson;
}

.required_text {
  padding-top: 5px;
  color: crimson;
  font-size: small;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
