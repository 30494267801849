.menu {
  display: flex;
  align-items: end;
  background-color: #0875c2;
  width: 100%;
  position: fixed;
  overflow: auto;
  color: white;
  height: 30px;
  padding: 10px 20px; /* Ruimte rondom de menu-items */
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: larger;
  box-shadow: 0px 5px 21px -7px rgba(0, 0, 0, 0.75);
}

.header-text {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  margin-left: 3em;
  margin-right: 1em;
}

.header-text img {
  width: 25px;        /* Pas de grootte aan naar wens */
  height: 25px;       /* Breedte en hoogte moeten gelijk zijn */
  border-radius: 50%; /* Maakt de afbeelding rond */
  object-fit: cover;  /* Zorgt ervoor dat de afbeelding netjes wordt bijgesneden */
}

.link {
  margin-right: 0.6em;
}

.menu ul {
  display: flex; /* Zorg ervoor dat de lijst flex-items bevat */
  list-style: none; /* Verwijder standaard opsommingstekens */
  padding: 0;
  margin: 0;
}

.menu li:last-child {
  margin-right: 0; /* Geen ruimte na het laatste item */
}

.menu li a {
  border: 1px solid white;
  /* border-right: 1px solid white;
  border-top: 1px solid white; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-decoration: none; /* Verwijder onderstreping */
  color: white; /* Zorg ervoor dat links wit zijn */
  font-size: 1rem; /* Pas de grootte van de tekst aan */
  padding-bottom: 1em;
  padding-top: 0.4em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  transition: background-color 0.3s ease; /* Animatie bij hover */
}

.menu li a:hover {
  background-color: #0a5d43; /* Kleur bij hover */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 1em;
}
